import React from "react";
import { NavigationBar } from ".";

import * as styles from "./Header.module.scss";

export const Header = ({}) => {
  const [navigationOpen, setNavigationOpen] = React.useState(false);

  const navItems = [
    { title: "Home", url: "/" },
    { title: "Nieuws", url: "/nieuws" },
    { title: "Dienstverlening", url: "/dienstverlening" },
    { title: "Portofolio", url: "/portofolio" },
    { title: "Contact", url: "/contact" },
  ];

  const renderHamburgerIcon = () => (
    <svg viewBox="0 0 75 48" width="20" height="40" fill="#FFF">
      <rect width="75" height="8"></rect>
      <rect y="20" width="75" height="8"></rect>
      <rect y="40" width="75" height="8"></rect>
    </svg>
  );

  return (
    <div>
      {/* Mobile Navigation */}
      <div className="md:hidden ">
        <div className="grid grid-cols-12">
          <a
            className={`${styles.hamburgerMenu} grid col-span-1 ml-2 mt-2`}
            onClick={() => setNavigationOpen(!navigationOpen)}
          >
            <div className="justify-self-center self-center">
              {renderHamburgerIcon()}
            </div>
          </a>

          <div className="col-span-11 justify-self-center">
            <a href="/">
              <img className="w-32" src="/img/logo-cempes@2x.jpg" alt="Logo" />
            </a>
          </div>
        </div>

        <div className={!navigationOpen ? "hidden" : styles.hamburgerContent}>
          <ul>
            {navItems.map((navItem) => (
              <a key={`hamburger-${navItem.title}`} href={navItem.url}>
                <li>{navItem.title}</li>
              </a>
            ))}
          </ul>
        </div>
      </div>

      <div className={styles.headerImage}></div>

      {/* Desktop Navigation */}
      <div className="hidden md:block">
        <div className={styles.headerBg}></div>

        <div className="container mx-auto max-w-screen-lg px-4">
          <div className="flex flex-row	">
            <div>
              <a href="/">
                <img
                  className={styles.logo}
                  src="/img/logo-cempes@2x.jpg"
                  alt="Logo"
                />
              </a>
            </div>
            <div className="flex-1 justify-self-end">
              <NavigationBar
                navItems={navItems}
                className={styles.navigationBar}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";

export const ContactForm = ({}) => {
  return (
    <form
      name="contact"
      method="POST"
      netlifydata-netlify="true"
      netlify-honeypot="bot-field"
      action="/contact/verstuurd"
    >
      <input type="hidden" name="form-name" value="contact" />
      <p className="hidden">
        <label>
          Don’t fill this out if you’re human: <input name="bot-field" />
        </label>
      </p>
      <div className="grid grid-cols-12 gap-6">
        <div className="flex col-span-4">
          <label htmlFor="id_name" className="block self-center">
            Naam:
          </label>
        </div>

        <div className="col-span-8">
          <input
            id="id_name"
            required
            type="text"
            name="name"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
      </div>

      <div className="grid grid-cols-12 gap-6 mt-2">
        <div className="flex col-span-4">
          <label htmlFor="id_email" className="block self-center">
            E-mail:
          </label>
        </div>

        <div className="col-span-8">
          <input
            id="id_email"
            required
            type="email"
            name="email"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
      </div>

      <div className="grid grid-cols-12 gap-6 mt-2">
        <div className="flex col-span-4">
          <label htmlFor="id_phone" className="block self-center">
            Telefoon:
          </label>
        </div>

        <div className="col-span-8">
          <input
            id="id_phone"
            required
            type="text"
            name="phone"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
      </div>

      <div className="grid grid-cols-12 gap-6 mt-2">
        <div className="flex col-span-4">
          <label htmlFor="id_content" className="block self-center">
            Opmerkingen:
          </label>
        </div>

        <div className="col-span-8">
          <textarea
            required
            id="id_content"
            name="content"
            rows={5}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
      </div>

      <div className="grid mt-3">
        <button
          type="submit"
          className="text-md justify-self-end self-end rounded-md text-white bg-[#008CBA] pl-5 pr-5 pt-3 pb-3"
        >
          Verzenden
        </button>
      </div>
    </form>
  );
};

import React from "react";

export const Title = ({ text, size }) => {
  const className = "font-cempesHeader text-cempesred uppercase";
  switch (size) {
    case "medium":
      return <h3 className={`${className} text-lg`}>{text}</h3>;
    case "large":
      return <h1 className={`${className} text-3xl`}>{text}</h1>;
  }
};

Title.defaultProps = {
  size: "large",
};

import React from "react";
import { StructuredText } from "react-datocms";

import { Title } from ".";

export const NewsBlock = ({ className, title, content, date }) => {
  return (
    <div className={`${className} flex p-3 mt-3 bg-[#F2F4F6]`}>
      <div>
        <div className="w-[75px] h-[75px] flex rounded-full bg-white shadow justify-center items-center ml-2 mt-2">
          <img
            src="/img/cempes-logo.png"
            alt="logo"
            className="w-[50px] h-[19px]"
          />
        </div>
      </div>
      <div className="ml-5 mt-3">
        <Title text={title} size="medium" />
        <span className="text-gray-600 font-bold">{date}</span>

        <div className="max-w-screen-sm">
          <StructuredText data={content} />
        </div>
      </div>
    </div>
  );
};

import React from "react";

import * as styles from "./NavigationBar.module.scss";

export const NavigationBar = ({ className, navItems }) => {
  return (
    <div className={`${className} ${styles.navbar}`}>
      <ul>
        {navItems.map((navItem) => (
          <li key={navItem.title}>
            <a href={navItem.url}>{navItem.title}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

import React from "react";

export const Button = ({ text, link }) => {
  return (
    <a
      className="text-white pl-5 pt-5 md:pt-4 bg-cempesred h-16 align-middle text-xl md:text-3xl font-cempesHeader"
      href={link}
    >
      {text}
    </a>
  );
};

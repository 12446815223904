import React from "react";
import { Footer, Header } from ".";

export const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      <main className="text-md">
        <div className="container mx-auto mt-6 max-w-screen-lg px-4">
          {children}
        </div>
      </main>
      <Footer />
    </div>
  );
};

import React from "react";

export const Footer = ({}) => {
  return (
    <div className="bg-bluegray p-4 text-white mt-20">
      <div className="container mx-auto max-w-screen-lg">
        Cémpes Vastgoed - Buizerd 24 - 1261SR Blaricum - info@cempes.nl
      </div>
    </div>
  );
};

import React from "react";
import { StructuredText } from "react-datocms";

export const PortfolioBlock = ({ image, project, activities, city }) => {
  return (
    <div>
      <img src={image.url} alt={image.alt} />
      <div>
        <strong className="text-cempesred">Project: </strong>
        <span>{project}</span>
      </div>
      <div>
        <strong className="text-cempesred">Plaats: </strong>
        <span>{city}</span>
      </div>
      <div>
        <strong className="text-cempesred">Werkzaamheden:</strong>
        <StructuredText data={activities} />
      </div>
    </div>
  );
};
